/* eslint-disable camelcase */

export const fireTag = (data) => {
  const eventData = {
    uri: window.location.href,
    ...data
  }
  if (window.dataLayer.user_id) {
    eventData.userInfo = {
      userId: window.dataLayer.user_id
    }
  } 
  if (window && window.dataLayer) {
    window.dataLayer.push({
      cloud_retail: {
        ...eventData
      }
    });
  }
}

export const viewCategoryPage = (categoryId) => {
  const data = {
    eventType: 'category-page-view',
    pageCategories: [categoryId],
    productDetails: undefined,
    searchQuery: undefined,
    attributionToken: undefined
  }
  fireTag(data);
}

export const viewDetailPage = (eventId) => {
  const queryString = window.location.search || '';
  const urlParams = new URLSearchParams(queryString);
  const attributionToken = urlParams.has('rtoken') ? urlParams.get('rtoken') : undefined; 
  const data = {
    eventType: 'detail-page-view',
    pageCategories: undefined,
    productDetails: [
      {
        product: {
          id: eventId
        }
      }
    ],
    searchQuery: undefined,
    attributionToken,
  }
  fireTag(data);
}

export const viewHomepage = () => {
  const data = {
    eventType: 'home-page-view',
    pageCategories: undefined,
    productDetails: undefined,
    searchQuery: undefined,
    attributionToken: undefined
  }
  fireTag(data);
}

export const search = (searchQuery) => {
  const data = {
    eventType: 'search',
    pageCategories: undefined,
    productDetails: undefined,
    searchQuery,
    attributionToken: undefined
  }
  fireTag(data);
}
